import React from "react"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"

import SEO from "../components/SEO"

import fiftyyears from "../images/50-years.jpg"

const Index = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO
        title="Stuarts Coaches"
        description="Now in retirement. Thank you to everyone who chose Stuarts Coaches for over 53 years"
      />
      <Heading title="We Have Retired!" />
      <div className="row main-page justify-content-center">
        <div className="col-12 col-md-8 index-page-text">
          <p>
            Thank you to everyone who chose Stuarts Coaches and for Touring Australia
            with us for the past 53 years.
          </p>
          <p>
            Warm wishes, Kevin and Leanne.
          </p>
        </div>
        <div className="col-8 col-md-4">
          <img src={fiftyyears} alt="" className="index-image" />
        </div>
      </div>
    </Layout>
  )
}

export default Index
